import PropTypes from 'prop-types';
import { reporter } from '@packages/reporter';
import * as Sentry from '@sentry/react';

import { onChunkLoadError } from '../../utils/chunkLoadErrorService';

function ErrorBoundary({
  children,
  fallbackComponent: FallbackComponent,
  loaderComponent: LoaderComponent = () => null,
}) {
  function onError(error, componentStack) {
    const isChunkLoadError = error.name === 'ChunkLoadError';

    if (isChunkLoadError) {
      const isReloading = onChunkLoadError();

      if (!isReloading) {
        reporter.error(error, { componentStack });
      }
    } else {
      reporter.error(error, { componentStack });
    }
  }

  function FallbackWithChunkLoadErrorLoader({ ...sentryErrorBoundaryProps }) {
    const isChunkLoadError =
      sentryErrorBoundaryProps.error.name === 'ChunkLoadError';

    // ChunkLoadErrors will cause a page reload, during that time, we show a loader/nothing
    if (isChunkLoadError) {
      return <LoaderComponent />;
    }

    return <FallbackComponent {...sentryErrorBoundaryProps} />;
  }

  return (
    <Sentry.ErrorBoundary
      fallback={FallbackWithChunkLoadErrorLoader}
      onError={onError}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  /** Rendered component in case of an error event */
  fallbackComponent: PropTypes.elementType,
  /** A loader component that appears when reloading the page on specific errors */
  loaderComponent: PropTypes.elementType,
};

export default ErrorBoundary;
