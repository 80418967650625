import transform from 'lodash/transform';
import simplify from 'simplify-js';
import { isEmpty } from 'lodash';

import { reporter } from '@packages/reporter';

export function dilutePoints(dataset = []) {
  const diluted = simplify(dataset, 5, true);
  return diluted.length < 6 ? dataset : diluted;
}

export function convertToPercentageByDimensions({
  item = [],
  width = 1080,
  height = 1920,
}) {
  return transform(
    item,
    (acc, value, key) => {
      acc[key] = value / (key === 'x' ? width : height);
    },
    {},
  );
}

export function convertToPixelsByDimensions(
  { item = [], width, height } = {
    item: [],
    width: 1080,
    height: 1920,
  },
) {
  return transform(
    item,
    (acc, value, key) => {
      acc[key] =
        key === 'x' ? Math.round(width * value) : Math.round(height * value);
      return acc;
    },
    {},
  );
}

export function convertLineStructureToPixelsByDimensions({
  line,
  width,
  height,
}) {
  return Object.entries(line).reduce((acc, [lineKey, lineValue]) => {
    if (lineKey === 'color') return acc;
    acc.push(convertToPixelsByDimensions({ item: lineValue, width, height }));
    return acc;
  }, []);
}

export const THRESHOLD = 0.03;

function getPositionByThreshold(current, next) {
  return next < THRESHOLD ? current : next;
}

/**
 * @description
 * - Firefox rendering engine is not dealing well with the minor changes to the mouse move
 * - We set a threshold to determine if the new point can be used
 * - If not, we discard it and use the previous point we have
 * @param {{x: Number, y: Number}} current - The last known data point
 * @param {{x: Number, y: Number}} next - The next candidate point based on user input (mouse pos)
 *
 * @returns {{x: Number, y: Number}} The new point to use
 */
export function getAverageCoordinate(current, next) {
  return {
    x: getPositionByThreshold(current.x, next.x),
    y: getPositionByThreshold(current.y, next.y),
  };
}

export function isDragForbidden(ev) {
  const isOutOfRange = Boolean(!ev.offsetX || !ev.offsetY);
  if (isOutOfRange) {
    reporter.warning('User tried to drag annotation point out of range', {
      isOutOfRange,
      requestedPoint: { x: ev.offsetX, y: ev.offsetY },
    });
  }
  const isOutsideOfImage = !['circle', 'svg', 'line', 'g'].includes(
    ev.target.localName,
  );
  return Boolean(isOutsideOfImage || isOutOfRange);
}

export function roundCoordinate(point) {
  return {
    x: Math.round(point.x * 100) / 100,
    y: Math.round(point.y * 100) / 100,
  };
}

export function convertCoordinateToPercentage(
  coordinate,
  originalDimensions = {},
) {
  const { width = 1080, height = 1920 } = originalDimensions;
  return roundCoordinate({ x: coordinate.x / width, y: coordinate.y / height });
}

const roundToClose = (value) => Math.round(value * 50) / 50;
export function isSameCoordinate(point1, point2) {
  return (
    roundToClose(point1.x) === roundToClose(point2.x) &&
    roundToClose(point1.y) === roundToClose(point2.y)
  );
}

export function convertCoordinateToPixels(coordinate, originalDimensions = {}) {
  const { width = 1080, height = 1920 } = originalDimensions;
  return roundCoordinate({
    x: Math.round(width * coordinate.x),
    y: Math.round(height * coordinate.y),
  });
}

export function getUpdatedPosition(imageRefDimensions, ev, oldPosition) {
  const newPosition = {
    x: ev.offsetX / imageRefDimensions.width,
    y: ev.offsetY / imageRefDimensions.height,
  };

  return roundCoordinate(
    !isEmpty(oldPosition)
      ? getAverageCoordinate(oldPosition, newPosition)
      : newPosition,
  );
}

export function isExistingCoordinate(points, coordinate) {
  return points.some((point) => isSameCoordinate(point, coordinate));
}

export function getCoordinate(ev, imageRefDimensions) {
  const coordinate = {
    x: ev.nativeEvent.offsetX / imageRefDimensions.width,
    y: ev.nativeEvent.offsetY / imageRefDimensions.height,
  };
  return coordinate.x < THRESHOLD && coordinate.y < THRESHOLD
    ? null
    : roundCoordinate(coordinate);
}
