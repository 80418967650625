import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import { tracking } from '@packages/tracking';
import { Button, Container, colors } from '@packages/ui';
import { useTranslation, Trans } from 'i18n';

import Spacer from 'components/Spacer';
import Text from 'components/Text';
import VStack from 'components/Stack/VStack';

import PlainTextButton from 'components/Button/PlainTextButton';
import ErrorImage from './error.png';
import CacheTableInstructions from './CacheTableInstructions';

const useStyles = makeStyles((theme) => ({
  mobileSizeOnly: {
    display: 'contents',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopSizeOnly: {
    display: 'contents',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  content: {
    flexBasis: '33%',
  },
  desc: {
    display: 'inline-block',
  },
  email: {
    color: 'inherit',
    fontWeight: 500,
  },
  errorImage: {
    width: 290,

    [theme.breakpoints.down('sm')]: {
      width: 260,
    },
  },
  centerPosition: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  title: {
    fontSize: '3rem',
    textAlign: 'center',
    margin: 0,
    lineHeight: '1.1',
    color: colors.text,
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  metadata: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  mobileInfo: {
    textAlign: 'center',
    padding: theme.spacing(0, 5),
    '& > a': {
      color: colors.primary,
      textDecoration: 'underline',
      fontWeight: 500,
    },
  },
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

function ErrorBoundaryPage({ error, resetError }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    tracking.trackEvent({
      eventName: 'Error page',
      props: {
        attemptedUrl: window.location.pathname,
      },
    });
    if (expand) {
      tracking.trackEvent({
        eventName: 'Error page click did not help',
        props: {
          attemptedUrl: window.location.pathname,
        },
      });
    }
  }, [expand]);

  const handleExpand = () => {
    setExpand((prev) => !prev);
  };

  const handleRefresh = () => {
    resetError();
    tracking.trackEvent({
      eventName: 'Error page click refresh',
      props: {
        attemptedUrl: window.location.pathname,
      },
    });
  };

  const supportMailInfo = {
    to: process.env.CONTACT_US_EMAIL,
    subject: 'Bug Report spot-portal',
    body: {
      intro: '========== Auto generated ==========',
      error: error.toString(),
      browser: `userAgent: ${navigator.userAgent}`,
    },
  };

  const href = `mailto:${supportMailInfo.to}?subject=${supportMailInfo.subject}&body=%0D%0A%0D%0A%0D%0A${supportMailInfo.body.intro}%0D%0A${supportMailInfo.body.error}%0D%0A${supportMailInfo.body.browser}`;

  return (
    <Container size="md">
      <div className={classes.centerPosition}>
        <div className={classes.errorWrapper}>
          <Spacer size={3} />
          <VStack className={classes.content}>
            <h1 className={classes.title}>{t('errorPage.title')}</h1>
            <Text className={classes.metadata}>{t('errorPage.subtitle')}</Text>

            <div className={classes.mobileSizeOnly}>
              <Text className={classes.mobileInfo}>
                <Trans i18nKey="errorPage.help.info.mobile">
                  <a href="/">home</a>
                  <a href={href} target="_blank" rel="noreferrer">
                    link
                  </a>
                </Trans>
              </Text>
            </div>

            <div className={classes.desktopSizeOnly}>
              <Button onClick={handleRefresh}>
                {t('errorPage.primary.cta')}
              </Button>
              <Spacer size={1} />
              <PlainTextButton onClick={handleExpand}>
                {t('errorPage.secondary.cta')}
              </PlainTextButton>

              <Collapse
                className={classes.metadata}
                in={expand}
                timeout={{
                  enter: 700,
                  exit: 500,
                }}
              >
                <Spacer size={1} />
                <Text>{t('errorPage.help.info')}</Text>
                <Spacer size={1} />
                <CacheTableInstructions />
                <Spacer size={1} />
                <Text>{t('errorPage.help.mail')}&nbsp;</Text>
                <a className={classes.email} href={href}>
                  <Text>{process.env.CONTACT_US_EMAIL}</Text>
                </a>
              </Collapse>
            </div>

            <Spacer size={3} />
          </VStack>
          <img src={ErrorImage} className={classes.errorImage} alt="error" />
        </div>
      </div>
    </Container>
  );
}

ErrorBoundaryPage.propTypes = {
  error: PropTypes.object.isRequired,
  resetError: PropTypes.func.isRequired,
};

export default ErrorBoundaryPage;
